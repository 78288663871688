.activation-upload-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.8125rem;
  margin-right: 19px;
  border-right: 1px solid #eee;
  flex: 0 0 75px;
  min-height: 235px;

  &-option {
    display: flex;
    align-items: center;
    flex: 1;
    padding-right: 25px;
    justify-content: center;
    cursor: pointer;
    .radio-button {
      color: var(--primary-color);
      margin-right: 5px;
      display: flex;
    }
  }
}

.network-block-table .ant-table {
  font-size: 0.875rem !important;
}

.action-transfer {
  padding-top: 16px;
}

@primary-color: #28BEA5;@border-radius-base: 8px;