.sms-tabs {
  .ant-tabs-tab {
    margin: 0 20px 0 0;
    padding: 12px 10px;
  }

  .ant-tabs-nav {
    margin: 0 !important;
  }
}

.ant-drawer-content-wrapper {
  width: 80%;
}

.badge-status-table-cell .ant-badge-status-dot {
  width: 10px;
  height: 10px;
}

.col-line-consumption {
  text-align: center;
  max-width: 120px;
  max-height: 49px;
  .progress-bar-consumption {
    .ant-progress-inner {
      border: 1px solid #dbdbdb;
      max-width: 100px;
    }

    .ant-progress-bg,
    .ant-progress-success-bg {
      height: 10px !important;
    }
  }
}

.ant-badge-status {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressbar-inventory .ant-progress-inner {
  box-shadow: 1px 2px 3px 1px #ddd;
}
.progressbar-inventory .ant-progress-outer {
  line-height: 0px !important;
}

// PROGRESS BAR
.bg-warningx {
  background-color: red !important;
}

progress {
  vertical-align: baseline;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.ant-slider-rail {
  background-color: #ccc;
}

.ant-slider:hover .ant-slider-rail {
  background-color: #ccc !important;
}

.input-addon-button {
  padding: 0px !important;
}

@primary-color: #28BEA5;@border-radius-base: 8px;