@font-face {
  font-family: 'Exo';
  src: url('../assets/fonts/exo.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/montserrat.ttf') format('truetype');
}

@import '~antd/dist/antd.less';

.ant-menu-submenu-popup {
  display: none;
}

.ant-form-item-label {
  margin-right: 12px;
  overflow: hidden !important;

  .ant-form-item-no-colon {
    &::after {
      display: none;
    }
  }
}

.ant-input-group-addon {
  padding: 0px !important;
}

.ant-notification-notice-btn {
  display: block;
  width: 100%;
}

.rc-virtual-list-scrollbar-show {
  display: block !important;
}

.ant-tabs {
  background: transparent !important;
}

.ant-picker-input input {
  text-transform: capitalize;
}

.ant-descriptions-item-container {
  display: flex;
  align-items: center;
  .ant-descriptions-item-label {
    font-size: 0.8rem;
  }

  .ant-descriptions-item-content {
    font-weight: bold;
    font-size: 0.9rem;
  }
}

.ant-carousel .slick-dots {
  z-index: 1 !important;
  li {
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    height: 6px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);

    button {
      height: 100%;
    }
  }
}

.group-tag-dropdown ul {
  max-height: 240px;
  overflow: auto;
}

@primary-color: #28BEA5;@border-radius-base: 8px;