.param-label {
  margin-bottom: 4px;
  > svg {
    color: var(--primary-color);
    vertical-align: middle;
    font-size: 0.45rem;
    margin-bottom: 2px;
    margin-right: 3px;
  }
}

.network-block {
  &-content {
    margin-top: 8px;
    color: #8C9696;
  }
  &-blocking-explanation {
    text-align: center;
    margin: 8px 0px 8px;
  }
  &-datepicker {
    display: flex;
    flex-direction: column;
  }
  &-both-warning {
    background: #f6b9793d;
    padding: 2px 8px;
    display: inline-block;
    position: relative;

    .both-warning-icon {
      position: absolute;
      color: #fbbd03;
      right: -8px;
      top: 32px;
    }
  }
  &-radio {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    .block-card-option {
      height: auto;
      padding: 15px 35px;
      display: flex;
      justify-content: center;
      min-width: 147px;
      .block-icon {
        position: absolute;
        top: 12px;
        left: 21px;
        color: var(--red-color);
        font-size: 1.275rem;
        border-radius: 50%;
      }
      .unblock-icon {
        position: absolute;
        top: 12px;
        left: 21px;
        color: var(--primary-color);
        font-size: 1.275rem;
        border-radius: 50%;
      }
      .signal-icon {
        font-size: 2rem;
      }
    }
  }
}

@primary-color: #28BEA5;@border-radius-base: 8px;