.button-previous {
  margin: 0 8px;
}

.button-download {
  padding: 0;
}

.ant-steps {
  margin-bottom: 15px;
}

.action-activation,
.action-validation,
.action-network-reset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.action-activation {
  .ant-row,
  .ant-select {
    width: 100%;
  }
}

.action-operator-restriction {
  .ant-select {
    width: 90%;
  }
}

@primary-color: #28BEA5;@border-radius-base: 8px;