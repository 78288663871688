.selectedItem {
  border-color: #28bea5;
  .ant-card-body {
    padding: 15px;
  }
}

.notSeleted {
  .ant-card-body {
    padding: 15px;
  }
}

@primary-color: #28BEA5;@border-radius-base: 8px;