.menu-item {
  span {
    text-transform: uppercase;

    font-size: 0.8rem;
  }

  strong {
    text-transform: none;
    font-weight: bold;
    font-size: 1rem;
  }
}

@primary-color: #28BEA5;@border-radius-base: 8px;