.contract-enable-disable-radio {
    padding: 16px;
    text-align: center;
    width: 100%;

    .ant-radio-button-wrapper {
        height: 100%;

        span:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 2px 8px;
            margin: 2px 8px;
            opacity: 0.8;

            svg {
                margin-top: 8px;
                margin-bottom: -10px;
                font-size: 1.6rem;
            }
        }
    }
}

.contract-enable-disable-alert {
    margin: 0 auto !important;
    margin-top: 24px !important;
    padding: 16px !important;
    opacity: 0.7;
    text-align: center;
    max-width: 400px;
}

.contract-enable-disable-footer {
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
        opacity: 0.6;
    }
}
@primary-color: #28BEA5;@border-radius-base: 8px;