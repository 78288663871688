.antd-steps-custom {
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 16px;
  .ant-steps-item {
    flex: 1 0 auto !important;
  }
}

.final-step {
  text-align: center;
  padding: 40px;
  opacity: 0.9;

  .final-step-circle {
    margin-bottom: 24px;
    font-size: 80px;
    color: green;
  }

  .final-step-paragraph {
    margin: 0 auto;
    font-size: 1.2rem;
    opacity: 0.9;

    p {
      margin-top: 8px;
      font-size: 0.8rem;

      strong {
        margin: 4px;
        font-size: 1rem;
      }

      strong:first-child {
        margin-right: 16px;
      }
    }
  }
}

@primary-color: #28BEA5;@border-radius-base: 8px;